import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { ADVANCED_PERMISSIONS, GLOBAL_PERMISSIONS, MODULE_PERMISSIONS, PERMISSION_LEVEL } from '../models/enum';
import { ResponseJson } from '../../shared/models/interfaces';
import { IPermission } from '../models/interfaces';
import { permissions } from '../models/constants';
import { differenceBy, isEqual, isEqualWith, isObject, keys } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class PermissionsService extends BaseService {

  apiUrl = environment.apiUrl + '/permissions'
  userPermissions = signal<{ permissionLevel: string, permissions: IPermission[] } | null>(null);

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getUserPermissions() {
    return this.get<ResponseJson<any>>(this.apiUrl);
  }

  private _getPermissions(): Observable<{ permissionLevel: string, permissions: IPermission[] }> {
    if (this.userPermissions()) {
      return of(this.userPermissions()!);
    } else {
      return this.getUserPermissions().pipe(
        map((res) => {
          if (res.success) {
            this.userPermissions.set(res.data.manage);
          }
          return this.userPermissions()!;
        })
      );
    }
  }

  hasPermission(module: MODULE_PERMISSIONS, permissionLevel?: ADVANCED_PERMISSIONS) {
    return this._getPermissions().pipe(map((userPermission) => {

      if (userPermission.permissionLevel === GLOBAL_PERMISSIONS.NO_ACCESS) {
        return false;
      }

      if (userPermission.permissionLevel === GLOBAL_PERMISSIONS.ADMIN_ACCESS) {
        return true;
      }

      if (userPermission.permissionLevel === GLOBAL_PERMISSIONS.ADVANCED_ACCESS) {

        const mainModule = module?.split('.')[0]
        const nestedModule = module?.split('.')[1]


        if (mainModule && nestedModule) {

          const mainModulePermission = this.userPermissions()?.permissions.find(permission => permission.name == mainModule)

          if (mainModulePermission) {
            const nestedModulePermission = mainModulePermission.nestedPermissions.find(permission => permission.name == nestedModule)

            if (nestedModulePermission && nestedModulePermission.checked) {

              const subNestedModule = module.split('.')[2]

              if (subNestedModule) {
                const subNestedModulePermission = nestedModulePermission.nestedPermissions!.find(permission => permission.name == subNestedModule)

                if (subNestedModulePermission && subNestedModulePermission.checked) {

                  if (subNestedModulePermission.selectedPermissionLevel == PERMISSION_LEVEL.ADMIN) {
                    return true

                  } else {

                    const permission = subNestedModulePermission.advancedPermissions!.find(permission => permission.name == permissionLevel)
                    if (permission && permission.checked) {
                      return true
                    }

                  }
                }
              }
              else {
                if (nestedModulePermission.selectedPermissionLevel == PERMISSION_LEVEL.ADMIN || nestedModulePermission.selectedPermissionLevel == PERMISSION_LEVEL.VIEW_ONLY) {
                  return true

                } else {
                  const permission = nestedModulePermission.advancedPermissions!.find(permission => permission.name == permissionLevel)

                  if (permission && permission.checked) {
                    return true
                  }
                }
              }
            }
          }
        }
      }
      return false;
    }))
  }

  mergePermissions(userPermissions: IPermission[]): IPermission[] {
    const permissionsObject: IPermission[] = structuredClone(permissions);
    const mergedPermissions: IPermission[] = [];

    //Finding all the missing permissions that doesn't exist in user permisssions
    const missingPermissions = permissionsObject.filter(permissionObject => !userPermissions.some(userPermission => userPermission.name === permissionObject.name));







    return userPermissions
  }

}