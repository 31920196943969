//LOCAL STORAGE KEYS
export enum LOCAL_STORAGE_KEYS {
    TOKEN = 'TOKEN'
};

//STATUS CODES
export enum RESPONSE_CODES {
    SUCCESS = 1000,
    ERROR = 1305,
    UNAUTHORIZED = 401,
    MISSING_SYSTEM_PERMISSIONS = 403,
};

//PERMISSIONS
export enum MOBILE_PERMISSIONS {
    NO_ACCESS = 'No Access',
    SECURITY_GUARD = 'Security Guard',
    SECURITY_SUPERVISOR = 'Security Supervisor'
}

export enum GLOBAL_PERMISSIONS {
    NO_ACCESS = 'No Access',
    ADMIN_ACCESS = 'Admin Access',
    ADVANCED_ACCESS = 'Advance Access'
}

export enum PERMISSION_LEVEL {
    ADMIN = 'Admin',
    ADVANCE = 'Advance',
    VIEW_ONLY = 'View Only'
}

export enum MODULE_PERMISSIONS {

    // General
    QUICK_CONTACTS = 'General.Quick Contacts',
    COMMUNICATIONS = 'General.Communications',
    DOCUMENTS = 'General.Documents',

    //Resident Management
    FLATS = 'Resident Management.Flats',
    HELPDESK_SETUP = 'Resident Management.Helpdesk.Helpdesk Setup',
    HELPDESK_TICKETS = 'Resident Management.Helpdesk.Helpdesk Tickets',
    RENOVATION_WORKS = 'Resident Management.Renovation Works',
    VIOLATION_SETUP = 'Resident Management.Violation Setup',
    ALL_VIOLAIONS = 'Resident Management.All Violations',

    //Staff Management
    ROLES_AND_DEPARTMENTS = 'Staff Management.Roles and Departments',
    STAFF_DIRECTORY = 'Staff Management.Staff Directory',
    HELPER = 'Staff Management.Helper',

    //Work Management
    ASSETS = 'Work Management.Assets',
    LOCATIONS = 'Work Management.Locations',
    WORK_PACKAGE = 'Work Management.Work Package',
    WORK_SCHEDULER = 'Work Management.Work Scheduler',
    WORKLOGS = 'Work Management.WorkLogs',
    ISSUES = 'Work Management.Issues',

    //Security Operations
    GATES = 'Security Operations.Gates',
    DEVICES = 'Security Operations.Devices',
    ACCESS_LOGS = 'Security Operations.Access Logs',
    PARCEL_LOGS = 'Security Operations.Parcel Logs',
    PATROLLING_SHIFTS = 'Security Operations.Patrolling Shifts',
    PATROL_LOGS = 'Security Operations.Patrol Logs',
    SECURITY_ALERTS = 'Security Operations.Security Alerts',

    //Resident Payments
    RESIDENT_PAYMENTS = 'Resident Payments.Resident Payments',

    //Accounting
    ACCOUNTS_AND_TRANSFER = 'Accounting.Accounts and Transfer',
    HEADERS = 'Accounting.Headers',
}


export enum ADVANCED_PERMISSIONS {

    //QUICK_CONTACTS
    VIEW_CONTACTS = 'View Contacts',
    ADD_CONTACTS = 'Add Contacts',
    EDIT_CONTACTS = 'Edit Contacts',
    DELETE_CONTACTS = 'Delete Contacts',

    //COMMUNICATIONS
    VIEW_NOTICES = 'View Notices',
    CREATE_NOTICES = 'Create Notices',
    DELETE_NOTICES = 'Delete Notices',

    //DOCUMENTS
    VIEW_DOCUMENTS = 'View Documents',
    CREATE_FOLDERS_FILES = 'Create Folder and Files',
    EDIT_FOLDER_FILES_DETAILS = 'Edit Details',
    DELETE_FOLDER_FILES = 'Delete Folder and Files',

    //FLATS
    VIEW_FLATS = 'View flats',
    ACTIVATE_FLATS = 'Activate flats',
    ADD_MEMBERS = 'Add Members',

    //HELPDESK_SETUP
    VIEW_CATEGORIES = 'View Categories',
    CREATE_CATEGORIES = 'Create Categories',
    EDIT_CATEGORIES = 'Edit Categories',
    DELETE_CATEGORIES = 'Delete Categories',

    //HELPDESK_TICKETS
    VIEW_TICKETS = 'View Tickets',
    RAISE_TICKETS = 'Raise Tickets',
    EDIT_ASSIGN_TICKETS = 'Edit/Assign Tickets',
    UPDATE_STATUS = 'Update Status',
    CLOSE_TICKETS = 'Close Tickets',
    REOPEN_TICKETS = 'Reopen Tickets',
    ADD_COMMENTS = 'Add Comments',
    TRANSFER_TICKETS = 'Transfer Tickets',

    //RENOVATION WORKS
    VIEW_INTERIOR_WORK_AND_RENOVATION = 'View Interior Work/Renovation',
    PERFORM_INSPECTION = 'Perform Inspection',
    ADD_RENOVATION_REQUEST = 'Add New Request',
    APPROVE_RENOVATION_REQUEST = 'Approve Request',
    DECLINE_RENOVATION_REQUEST = 'Decline Request',
    RAISE_VIOLATION = 'Raise Violation', // this works for both Interior Work and Violation Setup and All Violations
    EXTEND_RENOVATION_WORK = 'Extend Work',
    CLOSE_RENOVATION_WORK = 'Close Work',

    // VIOLATION SETUP
    VIEW_VIOLATIONS = 'View Violations',// this works for both violation setup and all violations
    ADD_VIOLATION_GROUPS = 'Add Violation Groups',
    DELETE_VIOLATION = 'Delete Violation',

    // ALL VIOLATIONS
    TAKE_ACTIONS = 'Take Actions',

    //ROLES_AND_DEPARTMENTS
    VIEW_ROLES_DEPARTMENTS = 'View Roles & Departments',
    CREATE_ROLES_DEPARTMENTS = 'Create Roles & Departments',
    EDIT_ROLES_DEPARTMENTS = 'Edit Roles & Departments',
    DELETE_ROLES_DEPARTMENTS = 'Delete Roles & Departments',

    //STAFF_DIRECTORY
    VIEW_STAFF = 'View Staff',
    ADD_STAFF = 'Add Staff',
    EDIT_STAFF = 'Edit Staff',
    DELETE_STAFF = 'Delete Staff',

    //HELPER
    VIEW_HELPERS = 'View Helpers',
    ADD_HELPERS = 'Add Helpers',
    EDIT_HELPERS = 'Edit Helpers',
    DELETE_HELPERS = 'Delete Helpers',

    //ASSETS
    VIEW_ASSETS = 'View Asset',
    ADD_ASSETS = 'Add an Asset',
    EDIT_ASSET_DETAILS = 'Edit Asset details',
    MANAGE_GEO_TAGS = 'Manage Geo-tags', // used in locations also
    DELETE_ASSETS = 'Delete Assets',

    //LOCATIONS
    VIEW_LOCATIONS = 'View Locations',
    CREATE_CLUSTER = 'Create Cluster',
    EDIT_CLUSTER = 'Edit Cluster',
    DELETE_CLUSTERS = 'Delete Clusters',

    //WORK_PACKAGE
    VIEW_WORK_PACKAGE = 'View Work Package',
    ADD_WORK_PACKAGE = 'Add Work Package',
    EDIT_WORK_PACKAGE = 'Edit Work Package',
    DUPLICATE_WORK_PACKAGE = 'Duplicate Work Package',
    DELETE_WORK_PACKAGE = 'Delete Work Package',

    //WORK_SCHEDULER
    VIEW_WORK_SCHEDULE = 'View Work Schedule',
    ADD_WORK_SCHEDULE = 'Add Work Schedule',
    EDIT_WORK_SCHEDULE = 'Edit Work Schedule',
    DEACTIVATE_DELETE_WORK_SCHEDULE = 'Deactivate And Delete Work Schedule',
    PAUSE_RESUME_WORK_SCHEDULE = 'Pause And Resume Work Schedule',

    //WORKLOGS
    VIEW_WORK = 'View Work',
    PERFORM_WORK = 'Perform Work',
    REVIEW_WORK = 'Review Work',
    COMPLETED_WORK = 'Completed Work',
    EXPIRED_WORK = 'Expired Work',

    //ISSUES
    VIEW_ISSUES = 'View Issues',
    RAISE_ISSUES = 'Raise Issues',
    EDIT_ASSIGN_ISSUES = 'Edit/Assign Issues',
    UPDATE_ISSUES = 'Update Issues',
    CLOSE_ISSUES = 'Close Issues',

    //GATES
    VIEW_GATES = 'View Gates',
    ADD_GATES = 'Add Gates',
    EDIT_GATES = 'Edit Gates',
    DELETE_GATES = 'Delete Gates',

    //DEVICES
    VIEW_DEVICES = 'View Devices',
    MANAGE_DEVICES = 'Manage Devices',
    EDIT_DEVICES = 'Edit Devices',
    DELETE_DEVICES = 'Delete Devices',

    //PATROLLING_SHIFTS
    VIEW_SHIFTS = 'View Shifts',
    CREATE_SHIFTS = 'Create Shifts',
    EDIT_SHIFT_DETAILS = 'Edit shift details',
    DELETE_SHIFTS = 'Delete Shifts',

    //RESIDENT PAYMENTS
    VIEW_ALL_PAYMENTS = 'View All Payments',
    SETUP_PAYMENTS = 'Set-up Payments',
    RAISE_INVOICE = 'Raise Invoice',
    VOID_INVOICE = 'Void Invoice',
    POST_RECEIPT = 'Post Receipt',
    VOID_RECEIPT = 'Void Receipt',
    INITIATE_REFUND = 'Initiate Refund',
    RETRY_REFUND = 'Retry Refund',

    //ACCOUNTS
    VIEW_ACCOUNTS = 'View Accounts',
    ADD_ACCOUNTS = 'Add Accounts',
    EDIT_ACCOUNT_DETAILS = 'Edit Details',
    ACTIVATE_ACCOUNT = 'Activate Account',
    DEACTIVATE_ACCOUNT = 'Deactivate Account',

    //TRANSFERS
    VIEW_TRANSFER = 'View Transfer',
    TRANSFER_AMOUNT = 'Transfer Amount',
    REVERSE_AMOUNT = 'Reverse Amount',

    //HEADERS
    VIEW_HEADERS = 'View Headers',
    CREATE_HEADERS = 'Create Headers',
    EDIT_HEADER_DETAILS = 'Edit Details',
    DELETE_HEADERS = 'Delete Headers',

}