import { IPermission } from "./interfaces";
import { ADVANCED_PERMISSIONS, PERMISSION_LEVEL } from "./enum";

export const permissions: IPermission[] = [
  {
    name: 'General',
    checked: false,
    nestedPermissions: [
      {
        name: 'Quick Contacts',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_CONTACTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_CONTACTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_CONTACTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_CONTACTS,
            checked: true
          }
        ]
      },
      {
        name: 'Communications',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_NOTICES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_NOTICES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_NOTICES,
            checked: true
          }
        ]
      },
      {
        name: 'Documents',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_DOCUMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_FOLDERS_FILES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_FOLDER_FILES_DETAILS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_FOLDER_FILES,
            checked: true
          }
        ]
      }
    ]
  },
  {
    name: 'Resident Management',
    checked: false,
    nestedPermissions: [
      {
        name: 'Flats',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_FLATS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ACTIVATE_FLATS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_MEMBERS,
            checked: true
          },
        ]
      },
      {
        name: 'Helpdesk',
        checked: false,
        nestedPermissions: [
          {
            name: 'Helpdesk Setup',
            checked: true,
            permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
            selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
            advancedPermissions: [
              {
                name: ADVANCED_PERMISSIONS.VIEW_CATEGORIES,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.CREATE_CATEGORIES,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.EDIT_CATEGORIES,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.DELETE_CATEGORIES,
                checked: true
              }
            ]
          },
          {
            name: 'Helpdesk Tickets',
            checked: true,
            permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
            selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
            advancedPermissions: [
              {
                name: ADVANCED_PERMISSIONS.VIEW_TICKETS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.RAISE_TICKETS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.EDIT_ASSIGN_TICKETS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.UPDATE_STATUS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.CLOSE_TICKETS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.REOPEN_TICKETS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.ADD_COMMENTS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.TRANSFER_TICKETS,
                checked: true
              }
            ]
          }
        ]
      },
      {
        name: 'Renovation Works',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_INTERIOR_WORK_AND_RENOVATION,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.PERFORM_INSPECTION,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_RENOVATION_REQUEST,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.APPROVE_RENOVATION_REQUEST,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DECLINE_RENOVATION_REQUEST,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RAISE_VIOLATION,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EXTEND_RENOVATION_WORK,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CLOSE_RENOVATION_WORK,
            checked: true
          }
        ]
      },
      {
        name: 'Violation Setup',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_VIOLATIONS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_VIOLATION_GROUPS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_VIOLATION,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RAISE_VIOLATION,
            checked: true
          }
        ],
      },
      {
        name: 'All Violations',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_VIOLATIONS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RAISE_VIOLATION,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.TAKE_ACTIONS,
            checked: true
          }
        ],
      },
    ]
  },
  {
    name: 'Staff Management',
    checked: false,
    nestedPermissions: [
      {
        name: 'Roles and Departments',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_ROLES_DEPARTMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_ROLES_DEPARTMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_ROLES_DEPARTMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_ROLES_DEPARTMENTS,
            checked: true
          }
        ]
      },
      {
        name: 'Staff Directory',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_STAFF,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_STAFF,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_STAFF,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_STAFF,
            checked: true
          }
        ]
      },
      {
        name: 'Helper',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_HELPERS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_HELPERS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_HELPERS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_HELPERS,
            checked: true
          },
        ],
      }
    ]
  },
  {
    name: 'Work Management',
    checked: false,
    nestedPermissions: [
      {
        name: 'Assets',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_ASSETS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_ASSETS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_ASSET_DETAILS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.MANAGE_GEO_TAGS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_ASSETS,
            checked: true
          }
        ]
      },
      {
        name: 'Locations',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_LOCATIONS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_CLUSTER,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_CLUSTER,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.MANAGE_GEO_TAGS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_CLUSTERS,
            checked: true
          }
        ]
      },
      {
        name: 'Work Package',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_WORK_PACKAGE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_WORK_PACKAGE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_WORK_PACKAGE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DUPLICATE_WORK_PACKAGE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_WORK_PACKAGE,
            checked: true
          }
        ]
      },
      {
        name: 'Work Scheduler',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_WORK_SCHEDULE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_WORK_SCHEDULE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_WORK_SCHEDULE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DEACTIVATE_DELETE_WORK_SCHEDULE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.PAUSE_RESUME_WORK_SCHEDULE,
            checked: true
          }
        ]
      },
      {
        name: 'Work Logs',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_WORK,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.PERFORM_WORK,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.REVIEW_WORK,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.COMPLETED_WORK,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EXPIRED_WORK,
            checked: true
          }
        ]
      },
      {
        name: 'Issues',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_ISSUES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RAISE_ISSUES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_ASSIGN_ISSUES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.UPDATE_ISSUES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CLOSE_ISSUES,
            checked: true
          }
        ]
      }
    ]
  },
  {
    name: 'Security Operations',
    checked: false,
    nestedPermissions: [
      {
        name: 'Gates',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_GATES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.ADD_GATES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_GATES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_GATES,
            checked: true
          },
        ],
      },
      {
        name: 'Devices',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_DEVICES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.MANAGE_DEVICES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_DEVICES,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_DEVICES,
            checked: true
          }
        ]
      },
      {
        name: 'Access Logs',
        checked: false,
        permissionLevel: PERMISSION_LEVEL.VIEW_ONLY,
        selectedPermissionLevel: PERMISSION_LEVEL.VIEW_ONLY
      },
      {
        name: 'Patrol Logs',
        checked: false,
        permissionLevel: PERMISSION_LEVEL.VIEW_ONLY,
        selectedPermissionLevel: PERMISSION_LEVEL.VIEW_ONLY
      },
      {
        name: 'Patrolling Shifts',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_SHIFTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_SHIFTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_SHIFT_DETAILS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_SHIFTS,
            checked: true
          },
        ],
      },
      {
        name: 'Parcel Logs',
        checked: false,
        permissionLevel: PERMISSION_LEVEL.VIEW_ONLY,
        selectedPermissionLevel: PERMISSION_LEVEL.VIEW_ONLY
      },
      {
        name: 'Security Alerts',
        checked: false,
        permissionLevel: PERMISSION_LEVEL.VIEW_ONLY,
        selectedPermissionLevel: PERMISSION_LEVEL.VIEW_ONLY
      }
    ]
  },
  {
    name: 'Resident Payments',
    checked: false,
    nestedPermissions: [
      {
        name: 'Resident Payments',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_ALL_PAYMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.SETUP_PAYMENTS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RAISE_INVOICE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.VOID_INVOICE,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.POST_RECEIPT,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.VOID_RECEIPT,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.INITIATE_REFUND,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.RETRY_REFUND,
            checked: true
          },
        ],
      }
    ],
  },
  {
    name: 'Accounting',
    checked: false,
    nestedPermissions: [
      {
        name: 'Accounts and Transfer',
        checked: false,
        nestedPermissions: [
          {
            name: 'Accounts',
            checked: true,
            permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
            selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
            advancedPermissions: [
              {
                name: ADVANCED_PERMISSIONS.VIEW_ACCOUNTS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.ADD_ACCOUNTS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.EDIT_ACCOUNT_DETAILS,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.ACTIVATE_ACCOUNT,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.DEACTIVATE_ACCOUNT,
                checked: true
              }
            ]
          },
          {
            name: 'Transfer',
            checked: true,
            permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
            selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
            advancedPermissions: [
              {
                name: ADVANCED_PERMISSIONS.VIEW_TRANSFER,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.TRANSFER_AMOUNT,
                checked: true
              },
              {
                name: ADVANCED_PERMISSIONS.REVERSE_AMOUNT,
                checked: true
              }
            ]
          },
        ]
      },
      {
        name: 'Headers',
        checked: false,
        permissionLevel: [PERMISSION_LEVEL.ADMIN, PERMISSION_LEVEL.ADVANCE],
        selectedPermissionLevel: PERMISSION_LEVEL.ADMIN,
        advancedPermissions: [
          {
            name: ADVANCED_PERMISSIONS.VIEW_HEADERS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.CREATE_HEADERS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.EDIT_HEADER_DETAILS,
            checked: true
          },
          {
            name: ADVANCED_PERMISSIONS.DELETE_HEADERS,
            checked: true
          }
        ]
      }
    ],
  }
]